* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  height: 100%;
}

body {
  background: #fafafa;
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
}

p {
  text-align: right;
  font-size: 50px;
  margin-top: 0;
  margin-bottom: 10px;
  margin-right: 10px;
}

p strong {
  font-size: 14px;
  color: #777;
}

.header-container {
  text-align: center;
  font-size: 28px!important;
  color: #2d3956;
  text-shadow: 1px 1px 2px white, -1px -1px 2px grey;
    padding: 10px 30px;
    margin: 0 auto 20px;
    background-color: rgb(255 255 255 / 20%);
    border-radius: 10px;
    width: 500px;
    box-shadow: 0px 5px 0px #2d3956, 0px -5px 0px #2d3956;
}

.header-container h1 {
  margin: 20px auto;
}

.details-container {
  display: flex;
  justify-content: space-between;
  border: 5px solid rgb(45 57 86 / 100%);
  background: rgb(236 245 255 / 60%);
  padding: 0 20px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.details-container .team h1, .details-container .points h1 {
  font-size: 24px;
  color: #2d3956;
}

.details-container .timer h1 {
  font-size: 24px;
  min-width: 120px;
  text-align: left;
}

.details-container > div{
  display: flex;
  align-items: center;
}

input {
  border: 5px solid #2d3956;
  text-align: center;
  padding: 10px 5px;
  font-size: 24px;
  display: flex;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  font-weight: bold;
  color: #2d3956;
  margin: 0 auto;
}

.points {
  color: #2d3956;
}

.cards-container {
  border: 5px solid rgb(45 57 86 / 100%);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 10px 20px -5px #607799;
  background: rgb(236 245 255 / 50%);
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.cards {
  display: grid;
  grid-template: repeat(5, 1fr) / repeat(12, 1fr);
  gap: 10px;
}

.emoji-card {
  height: 100px;
  width: 100px;
  border-radius: 2px;
  background: #000;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.emoji-card img {
  border-radius: 10px;
}

.emoji-card.flipped .inner {
  transform: rotateY(180deg);
}

.flipped {
  pointer-events: none !important;
}

.inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: 0.6s cubic-bezier(0.38, 0.02, 0.09, 1.66) all;
}

.emoji-card .front,
.emoji-card .back {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  border: 1px solid rgb(45 57 86 / 30%);
}

.emoji-card .front {
  background: #fff;
  transform: rotateY(180deg);
}

.emoji-card .back {
  background: #2d3956;
  color: #c74634;
  font-size: 100px;
  transition: 0.3s ease all;
}

.emoji-card .back:hover {
  background: #c74634;
  color: #fff;
  transform: scale(1.02);
}

/* MODAL------------------------------------------------------------ */

.modal-overlay{
  background-color: #999999;
  height: 100vh;
  left: 0;
  opacity: 0.95;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 500;
}

.modal-wrapper{
  display: flex;
  justify-content: center;
  left: 0;
  /* outline: 0;
  overflow-x: hidden;
  overflow-y: auto; */
  position: fixed;
  top: 25%;
  width: 100%;
  z-index: 1000;
}

.modal {
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-width: 650px;
  height: 350px;
  position: relative;
  z-index: 100;
  border: 5px solid #2d3956;
  box-sizing: content-box;
  justify-content: flex-start;
}

.modal-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  text-align: center;
  /* background: #fafafa; */
  background: #2d3956;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 5px solid #2d3956;
}

.modal-title {
  margin: 18px;
  width: 100%;
  /* color: #2d3956; */
  color: #fff;
}

.modal-description{ 
  color: #2d3956;
  text-align: center;
  width: 100%;
  height: auto;
  font-size: 28px;
}

.modal-description.final-score{ 
  font-size: 32px;
  font-weight: 900;
}

.modal-button {
  color: #fafafa;
  cursor: pointer;
  font-weight: bold;
  padding: 20px;
  width: 100%;
  border: none;
  font-size: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #2d3956;
}



/* SCOREBOARD------------------------------------------------------------ */

.scoreboard-container {
  background: #fff;
  border-radius: 10px;
  display: block;
  min-width: 750px;
  height: auto;
  position: relative;
  z-index: 100;
  border: 5px solid #2d3956;
  box-sizing: content-box;
  margin-top: 50px;
}

.scoreboard-header {
  display: block;
  width: 100%;
  text-align: center;
  /* border-top-left-radius: 6px;
  border-top-right-radius: 6px; */
  border-bottom: 5px solid #2d3956;
  color: #2d3956;
  box-shadow: 0px 2px 5px #2d3956;
}

.scoreboard-title {
  margin: 0;
  padding: 20px 0;
}

.scoreboard-body {
  width: 90%;
  padding: 10px;
  /* border: 1px solid #ddd; */
  background: #fff;
  margin: 40px;
  border-radius: 10px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  background: #2d3956;
  color: #fff !important;
  font-size: 16px;
}

td, th {
  border-bottom: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  font-size: 18px;
  color: #2d3956;
}

tr:first-child > td {
  font-size: 24px;
  font-weight: bold;
  color: #2d3956;
}

tr:nth-child(2) > td {
  font-size: 20px;
  font-weight: bold;
}

tr:nth-child(3) > td {
  font-size: 18px;
  font-weight: bold;
}

tr:last-child, tr:last-child > td {
  border-bottom: none;
}

.scoreboard-button {
  color: #607799;
  cursor: pointer;
  font-weight: bold;
  padding: 20px;
  width: 100%;
  border: none;
  font-size: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}